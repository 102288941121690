import PasswordStrengthMeter from '@/components/form/PasswordStrengthMeter'
import {Box, Typography} from '@mui/material'
import {Validator} from 'ra-core/dist/cjs/form/validate'
import React from 'react'
import {PasswordInput, regex} from 'react-admin'
import {useFormContext} from 'react-hook-form'

export default function Password({passwordName, confirmationName}: { passwordName: string, confirmationName: string }) {

    const {watch, formState, getFieldState} = useFormContext()
    const [password, passwordConf] = watch([passwordName, confirmationName])
    const {isDirty} = getFieldState(passwordName, formState)
    const {isDirty: isConfDirty} = getFieldState(confirmationName, formState)

    const validation = [
        regex(/[A-Z]/, 'Au moins une capitale'),
        regex(/[a-z]/, 'Au moins une minuscule'),
        regex(/[0-9]/, 'Au moins un chiffre'),
        regex(/[^((0-9)|(a-z)|(A-Z)|\s)]/, 'Au moins un caractère spécial'),
        regex(/.{12,}/, 'Au moins 12 caractères')
    ]

    // dD1!aaaaaaaa
    const passwordChecker = () => {
        console.log(password)
        if (password === null || password === passwordConf) {
            return undefined
        } else {
            return 'Vérifiez les mots de passe'
        }
    }

    return (
        <Box sx={{padding: '12px 0'}}>
            <Typography variant="subtitle2" gutterBottom>Réinitialiser le mot de passe</Typography>
            <PasswordInput
                label="Mot de passe"
                name="new_password"
                source="new_password"
                fullWidth
                helperText={false}
                validate={validation}
            />
            <PasswordStrengthMeter field={'new_password'}/>
            <PasswordInput
                label="Confirmez le mot de passe"
                name="new_password_confirmation"
                source="new_password_confirmation"
                fullWidth
                helperText={false}
                validate={passwordChecker as Validator}
                // @todo faire la validation du form -> pas pouvoir sauvegarder si pas valide
            />
            {isDirty && isConfDirty && password && passwordConf !== '' &&
                <Typography color={password === passwordConf ? 'green' : 'red'} sx={{paddingTop: '12px'}}>
                    {password === passwordConf ?
                        'Les mots de passe correspondent'
                        : 'Les mots de passe ne correspondent pas'
                    }
                </Typography>
            }
        </Box>
    )
}
