import {Coordinates} from '@/types'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import {Box, Button, TextField, Typography} from '@mui/material'
import mapboxgl, {MapLayerMouseEvent, MapLayerTouchEvent} from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useInput} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import Map, {MapRef, Marker} from 'react-map-gl'
import {MarkerDragEvent} from 'react-map-gl/maplibre'

export interface MapFieldInterface {
    source: string
    coordinates?: Coordinates
}

export interface NominatimAddressObject {
    house_number: string
    road: string
    village: string
    municipality: string
    county: string
    'ISO3166-2-lvl6': string
    state: string
    'ISO3166-2-lvl4': string
    region: string
    postcode: string
    country: string
    country_code: string
}

const MAPBOX_TOKEN = 'pk.eyJ1IjoiY2VyYmVyZS1kZXYiLCJhIjoiY2xtN3Nsd3VsMDRlcTNjbzVxcHI4NmVodSJ9.mSeVaEFf7c1A1BdDzxmL6Q' // Set your mapbox token here

export default function MapField({
                                     source,
                                     coordinates = {lng: 0, lat: 0}
                                 }: MapFieldInterface) {

    const {field} = useInput({source})
    const {field: fieldAddress} = useInput({source: 'address'})
    const {field: fieldCoordinates, fieldState: coordinatesState} = useInput({source: 'coordinates'})
    const {setValue} = useFormContext()

    const [baseCoordinates] = useState({
        lng: coordinates.lng,
        lat: coordinates.lat
    })

    const [markerCoordinates, setMarkerCoordinates] = useState({
        lng: coordinates.lng,
        lat: coordinates.lat
    })

    const [address, setAddress] = useState<NominatimAddressObject | null>(null)

    const [isTouched, setIstouched] = useState(false)

    const geocoder = useRef<MapboxGeocoder | null>(null)
    const geocoderContainer = useRef(null)

    const [viewState, setViewState] = useState({
        longitude: coordinates.lng,
        latitude: coordinates.lat,
        zoom: 14
    })

    const mapRef = useRef<MapRef | null>(null)

    const onLoad = useCallback(() => {
        // new MapboxGeocoder
    }, [])

    const handleReset = useCallback(() => {
        setMarkerCoordinates(baseCoordinates)
        reverseGeocoding(baseCoordinates)
        setIstouched(false)
    }, [])

    const updateAddress = useCallback(() => {
        if (address) {
            setValue('address', `${address.house_number ? `${address.house_number} ` : ''}${address.road}`)
        }
    }, [address])

    const onClick = useCallback((event: MapLayerMouseEvent | MapLayerTouchEvent) => {
        setIstouched(true)
        reverseGeocoding(event.lngLat)
        setMarkerCoordinates(event.lngLat)
    }, [])

    const onDragEnd = useCallback((event: MarkerDragEvent) => {
        reverseGeocoding(event.lngLat)
        setIstouched(true)
        setMarkerCoordinates(event.lngLat)
    }, [])

    const reverseGeocoding = useCallback((coordinates: Coordinates) => {
        fetch(`https://nominatim.openstreetmap.org/reverse?lat=${coordinates.lat}&lon=${coordinates.lng}&format=json`)
            .then(response => response.json())
            .then((response) => {
                setAddress(response.address)
            })
    }, [])

    useEffect(() => {
        setValue(source, markerCoordinates)
    }, [markerCoordinates])

    useEffect(() => {

        if (geocoderContainer.current && !geocoder.current) {

            geocoder.current = new MapboxGeocoder({
                accessToken: MAPBOX_TOKEN,
                mapboxgl: mapboxgl,
                placeholder: 'Renseignez votre adresse',
                marker: false
            })

            geocoder.current.addTo(geocoderContainer.current)

            geocoder.current.on('result', async (event: any) => {
                const coordinates = {
                    lng: event.result.center[0],
                    lat: event.result.center[1]
                }
                console.log(event)
                reverseGeocoding(coordinates)
                setMarkerCoordinates(coordinates)
                if (mapRef.current) {
                    mapRef.current.getMap().flyTo({
                        center: event.result.center,
                        speed: 500
                    })
                }
            })
        }
    }, [])

    return (
        <Box
            paddingBottom={3}
        >
            {/*<Typography gutterBottom>Coordonnées {coordinates.lng} / {coordinates.lat}</Typography>*/}
            <Box
                position={'relative'}
            >
                <div
                    style={{
                        zIndex: 10,
                        position: 'absolute',
                        inset: '15px auto auto 15px'
                    }}
                    ref={geocoderContainer}
                ></div>
                {isTouched && <>
                    <Button
                        onClick={handleReset}
                        variant={'contained'}
                        size={'medium'}
                        color={'error'}
                        style={{
                            zIndex: 10,
                            position: 'absolute',
                            inset: '15px 15px auto auto'
                        }}
                    >
                        Réinitialiser
                    </Button>
                </>}
                <Map
                    ref={mapRef}
                    initialViewState={viewState}
                    style={{width: '100%', height: 400}}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    mapboxAccessToken={MAPBOX_TOKEN}
                    onLoad={onLoad}
                    onClick={onClick}
                >

                    {/*<Geocoder*/}
                    {/*    mapRef={mapRef}*/}
                    {/*    onViewportChange={handleGeocoderViewportChange}*/}
                    {/*    mapboxApiAccessToken={MAPBOX_TOKEN}*/}
                    {/*    position="top-left"*/}
                    {/*/>*/}
                    <Marker
                        longitude={markerCoordinates.lng}
                        latitude={markerCoordinates.lat}
                        draggable={true}
                        onDragEnd={onDragEnd}
                    />
                </Map>
            </Box>
            <Box
                paddingTop={1}
                display={'flex'}
                gap={3}
            >
                <TextField
                    label="Latitude"
                    value={fieldCoordinates.value.lat ?? 0}
                    size={'small'}
                    disabled
                    fullWidth
                />
                <TextField
                    label="Longitude"
                    value={fieldCoordinates.value.lng ?? 0}
                    size={'small'}
                    disabled
                    fullWidth
                />
            </Box>
            {coordinatesState.error &&
                <Typography color={'red'} variant={'caption'}>{coordinatesState.error.message}</Typography>
            }
            {address && address.road &&
                <Box
                    paddingTop={1}
                    // style={{
                    //     zIndex: 10,
                    //     position: 'absolute',
                    //     inset: 'auto auto 15px 15px '
                    // }}
                >
                    <Typography variant={'subtitle2'}>Adresse relevée pour les
                        coordonnées {markerCoordinates.lng} / {markerCoordinates.lat}</Typography>
                    <Typography
                        gutterBottom>{address.house_number} {address.road}, {address.postcode} {address.village}</Typography>
                    <Button
                        color={'secondary'}
                        onClick={updateAddress}
                        size={'small'}
                        variant={'contained'}
                    >Mettre à
                        jour le champ avec
                        cette adresse
                    </Button>
                </Box>
            }
        </Box>
    )
    // const {
    //     input: {value, onChange},
    //     isRequired,
    //     label
    // } = useInput(props)
    //
    // const mapContainer = useRef(null)
    // const [map, setMap] = useState(null)
    //
    // useEffect(() => {
    //     if (!map) {
    //         const initializeMap = new mapboxgl.Map({
    //             container: mapContainer.current,
    //             style: 'mapbox://styles/mapbox/streets-v11',
    //             center: value || [0, 0],
    //             zoom: 10
    //         })
    //
    //         initializeMap.on('load', () => {
    //             setMap(initializeMap)
    //
    //             // Add geocoder (search functionality)
    //             const geocoder = new MapboxGeocoder({
    //                 accessToken: mapboxgl.accessToken,
    //                 mapboxgl: mapboxgl,
    //                 marker: true
    //             })
    //
    //             initializeMap.addControl(geocoder)
    //
    //             geocoder.on('result', (event) => {
    //                 const lat = event.result.geometry.coordinates[1]
    //                 const lng = event.result.geometry.coordinates[0]
    //                 onChange([lng, lat])
    //             })
    //
    //             if (value) {
    //                 new mapboxgl.Marker()
    //                     .setLngLat(value)
    //                     .addTo(initializeMap)
    //             }
    //
    //             initializeMap.on('click', function (event) {
    //                 onChange([event.lngLat.lng, event.lngLat.lat])
    //             })
    //         })
    //     }
    //     return () => map && map.remove()
    // }, [map, value, onChange])
    //
    // return (
    //     <Box
    //         marginBottom={3}
    //         borderRadius={1}
    //         overflow={'hidden'}
    //     >
    //         <Labeled label={label} isRequired={isRequired}>
    //             <div ref={mapContainer} style={{width: '100%', height: '400px'}}/>
    //         </Labeled>
    //     </Box>
    // )
}
