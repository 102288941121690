import communes from '@/communes'
import contributions from '@/contributions'
import {customTheme} from '@/layout/themes'
import medias from '@/medias'
import producers from '@/producers'
import producerActivities from '@/producers/activities'
import producerStores from '@/producers/stores'
import producerUsers from '@/producers/users'


import products from '@/products'
import '@/styles/app.scss'
import tags from '@/tags'
import terroirs from '@/terroirs'
import users from '@/users/admins'
import userClients from '@/users/clients'
import 'bootstrap/dist/css/bootstrap.min.css'
import jsonServerProvider from 'ra-data-json-server'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import {Admin, CustomRoutes, fetchUtils, Resource} from 'react-admin'
import {Route} from 'react-router'

import {authProvider, refreshAction} from './authProvider'

import Configuration from './configuration/Configuration'
import {Dashboard} from './dashboard'
import frenchMessages from './i18n/fr'
import {Layout, Login} from './layout'

const i18nProvider = polyglotI18nProvider(() => {
    return frenchMessages
}, 'fr')

const httpClient = async (url: string, options: fetchUtils.Options = {}) => {

    const now = new Date().getTime()
    const localExpiration = localStorage.getItem('token_expiration')
    const expiration = localExpiration ? parseInt(localExpiration) : 0

    if (now >= (expiration - (10 * 60 * 1000))) {
        await refreshAction()
    }

    const customHeaders = (options.headers ||
        new Headers({
            accept: 'application/json',
            'X-Origin': 'ra'
        })) as Headers

    const token = localStorage.getItem('token')
    customHeaders.set('Authorization', `Bearer ${token}`)

    options.headers = customHeaders

    return fetchUtils.fetchJson(url, options)
}

const dataProvider = jsonServerProvider(String(process.env.REACT_APP_DB_LOPT), httpClient)

const lightTheme = customTheme

const App = () => {

    return (
        <Admin
            title="L'OPT"
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
            // darkTheme={darkTheme}
        >
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration/>}/>
            </CustomRoutes>

            <Resource name="products" {...products} />
            <Resource name="terroirs" {...terroirs} options={{label: 'Terroirs'}}
                      recordRepresentation={(record) => `${record.name}`}/>
            <Resource name="communes" {...communes} options={{label: 'Communes'}}/>
            <Resource name="tags" {...tags} options={{label: 'Tags'}}/>
            <Resource name="producers" {...producers} options={{label: 'Entreprises'}}/>
            <Resource name="producers/activities" {...producerActivities} options={{label: 'Activités'}}/>
            <Resource name="producers/users" {...producerUsers} options={{label: 'Utilisateurs Pro.'}}/>
            <Resource name="producers/stores" {...producerStores} options={{label: 'Établissements'}}/>
            <Resource name="users/admins" {...users} options={{label: 'Administrateurs'}}/>
            {/*// @ts-ignore*/}
            <Resource name="users/clients" {...userClients} options={{label: 'Clients'}}/>

            {/*<Resource name="utilisateurs" {...utilisateurs} options={{ label: 'Utilisateurs' }} />*/}

            {/*<Resource name="utilisateurs_pros" {...utilisateurs_pros} options={{ label: 'Pros' }} />*/}
            {/*<Resource name="utilisateurs_pros_activites" {...utilisateurs_pros_activites} options={{ label: 'Activités' }} />*/}

            <Resource name="media" {...medias} options={{label: 'Médias'}}/>
            <Resource name="contributions" {...contributions} options={{label: 'Contributions'}}/>
        </Admin>
    )
}

export default App
