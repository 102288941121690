import React, {useEffect, useState} from 'react'
import {SelectInput, useCreate, useCreateSuggestionContext} from 'react-admin'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Select, MenuItem, Box, SelectChangeEvent, InputLabel, FormControl, Typography
} from '@mui/material'
import fetchApi from '@/lib/tools/api'
import {TagCategory, TagType} from '@/types'

export default function TagCreate ({ defaultTypeName }: { defaultTypeName: string }) {
	const { filter, onCancel, onCreate } = useCreateSuggestionContext();
	const [create] = useCreate();
	const [value, setValue] = React.useState(filter || '');

	const [category, setCategory] = useState<string | null>(null)
	const [type, setType] = useState<string | null>(null)

	const [categories, setCategories] = useState<TagCategory[]>([])
	const [types, setTypes] = useState<TagType[]>([])

	useEffect(() => {

		fetchApi('/tags/categories')
			.then(data => {
				setCategories(data)
				const defaultCategory = data.length ? data[data.length - 1] : null
				if (defaultCategory) {
					setCategory(defaultCategory.id)
				}
			})
		fetchApi('/tags/types')
			.then(data => {
				setTypes(data)
				const defaultType = data.find((i: TagType) => i.name === defaultTypeName)
				if (defaultType) {
					setType(defaultType.id)
				}
			})

	}, [])

	const handleChangeCategory = (event: SelectChangeEvent<typeof category>) => {
		const {
			target: { value }
		} = event
		setCategory(value)
	}

	const handleChangeType = (event: SelectChangeEvent<typeof category>) => {
		const {
			target: { value }
		} = event
		setType(value)
	}

	const handleSubmit = (event: any) => {
		event.preventDefault();
		create(
			'tags',
			{
				data: {
					name: value,
					type,
					category
				}
			},
			{
				onSuccess: (data) => {
					setValue('')
					onCreate(data)
				}
			}
		)
	}

	return (
		<Dialog open onClose={onCancel}>
			<form onSubmit={handleSubmit}>
				<DialogContent>
					<Box sx={{display: 'flex', flexDirection: 'column', gap: "12px"}}>
						<TextField
							label="Nom du tag"
							value={value}
							onChange={event => setValue(event.target.value)}
							variant={'outlined'}
							autoFocus
						/>
						<FormControl fullWidth>
							<Typography gutterBottom>Catégorie</Typography>
							<Select
								// labelId="category-label"
								// id="category"
								// label="Catégorie"
								className="selectInput"
								variant={'outlined'}
								value={category}
								onChange={handleChangeCategory}
								fullWidth
								// size="medium"
							>
								{categories.map(category =>
									<MenuItem
										key={category.id}
										value={category.id}
									>
										{category.name}
									</MenuItem>
								)}
							</Select>
						</FormControl>
						<FormControl fullWidth>
							<Typography gutterBottom>Type</Typography>
							<Select
								disabled
								className="selectInput"
								variant={'outlined'}
								value={
									type }
								onChange={handleChangeType}
								fullWidth
							>
								{types.map(type =>
									<MenuItem
										key={type.id}
										value={type.id}
									>
										{type.name}
									</MenuItem>
								)}
							</Select>
							{/* {defaultTypeName &&
								<Typography sx={{ fontStyle: 'italic', fontWeight: 'normal', fontSize: '12px', paddingTop: '4px'}}>
									Type par défaut : {defaultTypeName}
								</Typography>
							} */}
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button type="submit">Save</Button>
					<Button onClick={onCancel}>Cancel</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
