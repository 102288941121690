import ImageIcon from '@mui/icons-material/Image'
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    useMediaQuery,
    useTheme
} from '@mui/material'
import {GridColDef, GridValueGetterParams} from '@mui/x-data-grid'
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'
import {
    Datagrid,
    FunctionField,
    List,
    Pagination,
    ReferenceField,
    SearchInput,
    SelectInput,
    TextField as RaTextField,
    useNotify
} from 'react-admin'

import Modal from 'react-bootstrap/Modal'

import {HiLockClosed, HiLockOpen, HiOutlineUpload} from 'react-icons/hi'
import {RiCloseCircleFill} from 'react-icons/ri'
import './ModalPhotos.scss'

export interface Media {
    id: number;
    fileName: string;
    description: string;
    metas: Metas;
    category: Category;
}

export interface Category {
    id: number;
    name: string;
}

export interface Metas {
    size: Size;
    credit?: Credit;
    licence?: Credit;
}

export interface Credit {
    name: string;
    url: string;
}

export interface Size {
    width: number;
    height: number;
}

export const PARAMS = {
    categories: {
        terroir: 'Terroir',
        product: 'Produit',
        producer: 'Producteur',
        localisation: 'Localisation',
        user: 'Utilisateur'
    }
}

const ModalPhotos = (props: any) => {

    const {render, label, resource, setTarget} = props

    // Modal affiché par défaut
    const [show, setShow] = useState(false)
    // Affichage de la fenêtre générale -> sinon affiche la page téléchargement
    const [general, setGeneral] = useState(true)
    // Modal étendu
    const [extended, setExtended] = useState(false)
    // Affiche par liste d'images
    const [imageList, setImageList] = useState(true)
    // Propotionnalité d'échelle des dimensions d'une image
    const [locked, setLocked] = useState(true)

    const [loading, setLoading] = useState(false)

    // Classes
    const [modalStatus, setModalStatus] = useState('modal-photos')
    const [title, setTitle] = useState('Ajout/Selection d\'une image')

    // Filtres de recherche
    const [filterCat, setFilterCat] = useState<string | null>(null)
    const [filterQ, setFilterQ] = useState('')

    // Sélection d'une image
    const [mediaImages, setMediaImages] = useState<any>([])
    const [mediaCategories, setMediaCategories] = useState<any>([])
    const [selected, setSelected] = useState(false)
    const [selectedItem, setSelectedItem] = useState<Media | null>(null)
    const [upload, setUpload] = useState(false)

    const mediaImagesFiltered = mediaImages.filter((image: any) => {
        return image && image.description && image.description.toLowerCase().includes(filterQ.toLowerCase())
    })

    // Caractéristiques d'une image
    const [file, setFile] = useState<File | null>(null)
    const [imagePreview, setImagePreview] = useState<{
        result: string | ArrayBuffer | null,
        width: number,
        height: number
    } | null>(null)
    const [id, setID] = useState(0)
    const [source, setSource] = useState<Media | null>(null)
    const [description, setDescription] = useState('')
    const [defaultCategory, setDefaultCategory] = useState(props.resource || 'current')
    const [categoryId, setCategoryId] = useState<any>([])

    const [largeur, setLargeur] = useState<any>(undefined)
    const [hauteur, setHauteur] = useState<any>(undefined)
    const [ratio, setRatio] = useState(0)
    const [type, setType] = useState('')

    const [creditLibelle, setCreditLibelle] = useState<string | undefined>('')
    const [creditURL, setCreditURL] = useState<string | undefined>('')
    const [licenceLibelle, setLicenceLibelle] = useState<string | undefined>('')
    const [licenceURL, setLicenceURL] = useState<string | undefined>('')

    // Efface le dossier "uploads"
    const [clear, setClear] = useState(false)

    // Efface les données
    const [resetData, setResetData] = useState(false)

    // Responsivité pour la liste d'images
    const cols = useColsForWidth()

    // Notification
    const notify = useNotify()

    // Evènements ouvrir/fermer la modal
    const handleShow = () => {
        setShow(true)
        setLoading(true)
    }

    const handleClose = () => {
        setShow(false)
        setSelected(false)
        setFilterQ('')

        handleShowGeneral()
    }

    // Evènements des boutons sur la modal
    const handleExtended = () => {
        setModalStatus('modal-photos extended')
        setTitle('Rechercher/Selectionner une image')
        setExtended(true)
        setLoading(true)
    }

    const handleShrunk = () => {
        setModalStatus('modal-photos')
        setTitle('Ajout/Selection d\'une image')
        setExtended(false)
    }

    const handleShowGeneral = () => {
        handleShrunk()
        setGeneral(true)

        setResetData(!resetData)
        upload && handleRemoveUploadFile()
    }

    const handleShowDownload = () => {
        handleShrunk()
        setGeneral(false)

        setResetData(!resetData)
    }

    const handleSelect = (item: any) => {
        setSelectedItem(item)
        setSelected(true)
        handleShrunk()

        if (item) {

            setID(item.id)
            setSource(item)

            setDescription(item.description)
            setLargeur(item.metas.size.width)
            setHauteur(item.metas.size.height)
            setRatio(item.metas.size.width / item.metas.size.height)

            setCreditLibelle(item.metas.credit?.name)
            setCreditURL(item.metas.credit?.url)
            setLicenceLibelle(item.metas.licence?.name)
            setLicenceURL(item.metas.licence?.url)
        }

        setSelected(false)
    }

    const handleChoose = () => {
        handleShrunk()

        if (selectedItem) {

            setID(selectedItem.id)
            setSource(selectedItem)

            setDescription(selectedItem.description)
            setLargeur(selectedItem.metas.size.width)
            setHauteur(selectedItem.metas.size.height)
            setRatio(selectedItem.metas.size.width / selectedItem.metas.size.height)

            setCreditLibelle(selectedItem.metas.credit?.name)
            setCreditURL(selectedItem.metas.credit?.url)
            setLicenceLibelle(selectedItem.metas.licence?.name)
            setLicenceURL(selectedItem.metas.licence?.url)
        }

        setSelected(false)
    }

    const handleRemoveUploadFile = () => {
        setClear(!clear)
        setResetData(!resetData)
        setImagePreview(null)
    }

    // const formData = new FormData();
    // formData.append('file', e.target.files[0]);
    //
    // if (e) {
    //     if (e.length === 0)
    //         return;
    //
    //     const url = String(process.env.REACT_APP_DB_LOPT)  + '/media/upload';
    //
    //     fetch(url, {method: 'POST', body: formData })
    //         .then((response: any) => response.json())
    //         .then(data => {
    //             //console.log(data)
    //
    //             if ( data.result == 1) {
    //                 setHauteur(data.height);
    //                 setLargeur(data.width);
    //                 setSource('/images' + data.source);
    //
    //                 notify(data.status, {
    //                     type: 'info',
    //                     messageArgs: { smart_count: 1 },
    //                 });
    //
    //                 setUpload(true)
    //             } else {
    //                 notify(data.status, {
    //                     type: 'warning',
    //                     messageArgs: { smart_count: 1 },
    //                 });
    //             }
    //         })
    // }

    const handleSubmit = () => {
        if (file) {
            const url = String(process.env.REACT_APP_DB_LOPT) + '/media/upload'

            const formData = new FormData()
            formData.append('file', file)
            formData.append('description', description)
            formData.append('category', defaultCategory ? defaultCategory : categoryId)
            formData.append('metas', JSON.stringify({
                size: imagePreview ? {
                    width: imagePreview.width,
                    height: imagePreview.height
                } : null,
                credit: {
                    name: creditLibelle,
                    url: creditURL
                },
                licence: {
                    name: licenceLibelle,
                    url: licenceURL
                }
            }))

            fetch(url, {
                method: 'POST',
                body: formData
            })
                .then((response: any) => response.json())
                .then(data => {
                    //console.log(data)

                    if (data.result == 1) {
                        setID(data.id)
                        setSource(data.source)
                        setLargeur(imagePreview?.width)
                        // setHauteur(imagePreview?.height)
                        setUpload(false)
                        setGeneral(true)

                        notify(data.status, {
                            type: 'info',
                            messageArgs: {smart_count: 1}
                        })

                    } else {
                        notify(data.status, {
                            type: 'warning',
                            messageArgs: {smart_count: 1}
                        })
                    }
                })
        }
    }

    const handleInsert = () => {
        if (source != undefined) {
            setTarget(source)
            handleClose()
        }
    }

    // Toggle icon de Lock
    const Lock = (props: any) => {

        const {locked} = props

        return (
            <>
                {locked ? (
                    <HiLockClosed/>
                ) : (
                    <HiLockOpen/>
                )}
            </>
        )
    }

    // // ###################
    // // EFFACER LES DONNEES DU DOSSIER UPLOADS

    // useEffect(() => {
    //     if (upload) {
    //         const url = String(process.env.REACT_APP_DB_LOPT)  + '/media_images?clear';
    //         fetch(url, {method: 'DELETE', body: JSON.stringify({clear: true, target: source })})

    //         setUpload(false)
    //     }

    // }, [clear])

    // ###################
    // MISE EN ECHELLE DES IMAGES

    useEffect(() => {
        if (locked && ratio != largeur / hauteur)
            setRatio(largeur / hauteur)
    }, [locked])

    useEffect(() => {
        if (locked && source && (largeur != 0 && hauteur != 0)) {

            if (type == 'L') {
                const hauteurScaled: any = largeur / ratio
                setHauteur(parseInt(hauteurScaled))

            } else {
                const largeurScaled: any = hauteur * ratio
                setLargeur(parseInt(largeurScaled))
            }

        }
    }, [hauteur, largeur])

    // ###################
    // CHARGEMENT DES DONNEES
    useEffect(() => {
        const getOptions = () => {
            /**
             * Categories
             */

            fetch(process.env.REACT_APP_DB_LOPT + '/media/categories?_end=null')
                .then(res => res.json())
                .then((res) => {
                    setMediaCategories(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))

                    const categoryWithName = res.find((category: any) => (category?.name).toLowerCase() == resource)
                    if (categoryWithName) {
                        const id = categoryWithName.id
                        setDefaultCategory(id)
                        setFilterCat(id)
                    }
                })

            setLoading(false)
        }

        // const getImages = async () => {
        //
        //     const urlObject = new URL(`${process.env.REACT_APP_API_VERSION}/media`, process.env.REACT_APP_DB_LOPT)
        //
        //     if (filterCat) {
        //         urlObject.searchParams.append('category', filterCat)
        //     }
        //
        //     const res = await fetch(urlObject.toString())
        //     const json = await res.json()
        //
        //     setMediaImages(json)
        //     setLoading(false)
        // }

        if (loading) {
            getOptions()
            // fetchImages()
        }
    }, [loading])

    const fetchImages = useCallback(async () => {

        const urlObject = new URL(`${process.env.REACT_APP_API_VERSION}/media`, process.env.REACT_APP_DB_LOPT)

        if (filterCat) {
            urlObject.searchParams.append('category', filterCat)
        }

        if (filterQ) {
            urlObject.searchParams.append('q', filterQ)
        }

        const res = await fetch(urlObject.toString())
        const json = await res.json()

        setMediaImages(json)
        setLoading(false)

    }, [filterQ, filterCat, mediaCategories])

    useEffect(() => {
        // fetchImages()
    }, [mediaCategories, filterQ])

    // ###################
    // EFFACEMENT DES DONNEES

    useEffect(() => {
        setID(0)
        setSelectedItem(null)
        setSource(null)
        setDescription('')

        setLargeur(0)
        setHauteur(0)
        setRatio(0)

        setCreditLibelle('')
        setCreditURL('')
        setLicenceLibelle('')
        setLicenceURL('')

    }, [resetData])

    useEffect(() => {
        if (file) {
            const fileReader = new FileReader()

            fileReader.onload = () => {
                const img = new Image()
                img.onload = () => {
                    setImagePreview({
                        height: img.height,
                        width: img.width,
                        result: fileReader.result
                    })
                }
                img.src = fileReader.result as string
            }

            fileReader.readAsDataURL(file)
        }
    }, [file])

    // const postFilters = [
    //     // <TextInput label="Search" source="q" alwaysOn/>,
    //     <TextInput label="Title" source="title" defaultValue="Hello, World!"/>
    // ]
    //
    // const ListToolbar = () => (
    //     <Stack direction="row" justifyContent="space-between">
    //         <div>
    //             <FilterButton filters={postFilters}/>
    //         </div>
    //     </Stack>
    // )

    return (
        <>
            {render == 'toogle' ? (
                <ToggleButton
                    aria-label="Gestionnaire de photos"
                    title="Gestionnaire de photos"
                    value="image"
                    onClick={handleShow}
                    sx={{mR: 8, mB: 8, p: '7px'}}
                >
                    <ImageIcon fontSize="inherit"/>
                </ToggleButton>
            ) : (
                <Button variant="contained" onClick={handleShow}>{label}</Button>
            )
            }

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName={modalStatus}
                backdrop="static"
            >

                <Modal.Header closeButton>
                    <h6 className="mt-auto mb-auto"> {title} </h6>
                </Modal.Header>

                <Modal.Body>
                    <Box sx={{display: 'flex'}}>
                        <Box id="nav" sx={{display: 'flex', flexDirection: 'column', width: '150px'}}>
                            <Button className="button-link" onClick={() => {
                                !general && handleShowGeneral()
                            }} disableRipple> Général </Button>
                            <Button sx={{opacity: extended ? 0.5 : 1}} className="button-link" onClick={() => {
                                general && handleShowDownload()
                            }} disableRipple disabled={extended}> Télécharger </Button>
                        </Box>

                        <Box display={{lg: 'flex', md: 'block'}} width={{md: 1}}>
                            {general ?
                                // Fenêtre "Général"
                                <Box
                                    sx={{
                                        width: extended ? '100%' : '100%',
                                        display: 'flex',
                                        flex: '1 1 30%',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        opacity: extended ? 0.5 : 1
                                    }}>
                                    <Box
                                        sx={{
                                            height: '75vh',
                                            marginLeft: '20px',
                                            marginRight: extended ? '20px' : 'none',
                                            transition: theme =>
                                                theme.transitions.create('width', {
                                                    easing: theme.transitions.easing.sharp,
                                                    duration: theme.transitions.duration.leavingScreen
                                                })
                                        }}>

                                        <Box sx={{display: 'flex', gap: '20px'}}>
                                            <TextField id="source" label="Source" value={source?.fileName} size="small"
                                                       fullWidth helperText={false} variant="filled" required disabled/>

                                            <IconButton sx={{padding: '15px !important;', fontSize: '1.5rem'}}
                                                        aria-label="Télécharger une image" onClick={handleExtended}
                                                        disabled={extended}>
                                                <HiOutlineUpload/>
                                            </IconButton>
                                        </Box>

                                        <Box mt="1em"/>

                                        <TextField
                                            id="description"
                                            label="Titre/Description"
                                            value={description}
                                            variant="filled"
                                            fullWidth
                                            disabled
                                        />

                                        <Box mt="1em"/>

                                        {/* <Box sx={{ display: "flex", gap: "20px" }}>
                                            <TextField
                                                sx={{ width: "15vh" }}
                                                label="Largeur (px)"
                                                size="small"
                                                type="number"
                                                value={imagePreview?.width || ''}
                                                variant="filled"
                                                helperText={false}
                                                onChange={(event: any) => {
                                                    // uniquement les nombres
                                                    if (/^\d+$/.test(event.target.value))
                                                        event.target.value == '' ? setLargeur(0) : setLargeur(parseInt(event.target.value));
                                                    setType("L")
                                                }}
                                                //InputProps={{inputProps: {inputMode: 'numeric', min: 0}}}
                                                disabled={extended}
                                            />

                                            <TextField
                                                sx={{ width: "15vh" }}
                                                label="Hauteur (px)"
                                                size="small"
                                                type="number"
                                                variant="filled"
                                                value={imagePreview?.height || ''}
                                                helperText={false}
                                                onChange={(event: any) => {
                                                    if (/^\d+$/.test(event.target.value))
                                                        event.target.value == '' ? setHauteur(0) : setHauteur(parseInt(event.target.value))
                                                    setType("H")
                                                }}
                                                //InputProps={{inputProps: {inputMode: 'numeric'}}}
                                                disabled={extended}
                                            />

                                            <IconButton sx={{ padding: "15px !important;", fontSize: "1.5rem" }} disabled={extended} onClick={() => setLocked(!locked)}>
                                                < Lock locked={locked} />
                                            </IconButton>
                                        </Box>

                                        <Box mt="1em" />

                                        <TextField id="outlined-basic" label="Classe(s) pour cette image" size="small" variant="filled" fullWidth helperText={false} disabled={extended} />

                                        <Box mt="2em" />*/}

                                        <h6>Crédits</h6>

                                        <Box sx={{display: 'flex', gap: '20px'}}>
                                            <TextField label="Nom de l'auteur" value={creditLibelle || ''}
                                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCreditLibelle(event.target.value)}
                                                       size="small" variant="filled" fullWidth disabled/>

                                            <TextField label="Lien du site de l'auteur" value={creditURL || ''}
                                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCreditURL(event.target.value)}
                                                       size="small" variant="filled" fullWidth disabled/>
                                        </Box>

                                        <Box mt="1em"/>

                                        <Box sx={{display: 'flex', gap: '20px'}}>
                                            <TextField label="Nom de la licence" value={licenceLibelle || ''}
                                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLicenceLibelle(event.target.value)}
                                                       size="small" variant="filled" fullWidth disabled/>

                                            <TextField label="Lien vers les détails de la licence"
                                                       value={licenceURL || ''}
                                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLicenceURL(event.target.value)}
                                                       size="small" variant="filled" fullWidth disabled/>
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        gap: '10px' /*marginRight: extended ? "20px" : "none"*/
                                    }}>
                                        <Button onClick={handleClose} disabled={extended}>Annuler</Button>
                                        <Button variant="contained" onClick={handleInsert}
                                                disabled={extended}>Insérer</Button>
                                    </Box>
                                </Box>

                                :

                                // Fenêtre "Télécharger"
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: 1
                                }}>
                                    <Box
                                        sx={{
                                            height: '75vh',
                                            marginLeft: '20px',
                                            marginRight: extended ? '20px' : '4px',
                                            overflowY: 'scroll'
                                        }}>

                                        <Box
                                            sx={{
                                                marginRight: 'auto',
                                                marginLeft: 'auto',
                                                height: '20vh',
                                                width: '100%',
                                                borderRadius: '5px',
                                                border: !imagePreview ? '2px dashed #59626B' : 'none',
                                                textAlign: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                placeContent: 'center',
                                                alignItems: 'center',
                                                gap: '10px'
                                            }}>

                                            {!imagePreview ? (
                                                <>
                                                    {/* <h6>Glisser/Déposer une image</h6> */}

                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        sx={{width: '50%'}}
                                                    >
                                                        Parcourir
                                                        <Input
                                                            onChange={({target}: ChangeEvent<HTMLInputElement>) => {
                                                                const input = target as HTMLInputElement
                                                                if (!input.files?.length) {
                                                                    return
                                                                }
                                                                setFile(input.files[0])
                                                            }}
                                                            hidden /*accept="image/*"*/
                                                            type="file"
                                                        />
                                                    </Button>
                                                </>
                                            ) : (
                                                <Box sx={{position: 'relative', height: '100%'}}>
                                                    <Box
                                                        sx={{
                                                            width: 1,
                                                            height: 1
                                                        }}
                                                        component="img"
                                                        src={imagePreview.result as string}
                                                    />
                                                    <IconButton sx={{
                                                        position: 'absolute',
                                                        color: '#d32c28',
                                                        top: '10px',
                                                        right: '10px',
                                                        fontSize: '1.5em'
                                                    }} onClick={handleRemoveUploadFile}>
                                                        <RiCloseCircleFill/>
                                                    </IconButton>
                                                </Box>

                                            )}

                                        </Box>

                                        <Box mt="1em"/>

                                        <h6>Général</h6>

                                        <TextField value={description}
                                                   onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
                                                   id="outlined-basic" label="Titre/Description" size="small"
                                                   variant="filled" fullWidth helperText={false} required/>

                                        <FormControl required variant="filled"
                                                     sx={{width: '75%', margin: '8px 0 4px 0'}}>
                                            <InputLabel id="input-label-cat">Choisir une catégorie</InputLabel>
                                            <Select
                                                disabled={defaultCategory}
                                                defaultValue={defaultCategory}
                                                labelId="cat"
                                                id="select-new-categories"
                                                variant="filled"
                                                onChange={(event: any, children: any) => {
                                                    setCategoryId(children.props.value)
                                                }}
                                            >
                                                {mediaCategories.map((category: any) => (
                                                    <MenuItem key={category.id}
                                                              value={category.id}> {category.name} </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <Autocomplete
                                            id="tags"
                                            multiple
                                            // onCreate={(e: any) => {
                                            //     const newTag = { id: String(LastIDTags), LIBELLE: e.toLowerCase() };
                                            //     tags.push(newTag);

                                            //     setLastIDTags(LastIDTags + 1)
                                            //     return newTag;
                                            // }}
                                            //createItemLabel={"Crée le tag %{item}"
                                            options={['test']}
                                            fullWidth
                                            // parse={value => value && value.map((v: any) => ({ id: v, LIBELLE: String(tags.filter((val: any) => val.id == v).map((filteredObj: any) => filteredObj.LIBELLE)) }))}
                                            // format={value => value && value.map((v: any) => v.id)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Tags" variant="filled"/>
                                            )}
                                            disabled
                                        />

                                        <Box sx={{display: 'flex', gap: '20px'}}>
                                            <TextField label="Largeur (px)" size="small" variant="filled" disabled
                                                       value={imagePreview ? imagePreview.width : ''}/>
                                            <TextField label="Hauteur (px)" size="small" variant="filled" disabled
                                                       value={imagePreview ? imagePreview.height : ''}/>
                                        </Box>

                                        <Box mt="2em"/>

                                        <h6>Crédits</h6>

                                        <Box sx={{display: 'flex', gap: '20px'}}>
                                            <TextField label="Nom de l'auteur" value={creditLibelle || ''}
                                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCreditLibelle(event.target.value)}
                                                       size="small" variant="filled" fullWidth/>

                                            <TextField label="Lien du site de l'auteur" value={creditURL || ''}
                                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCreditURL(event.target.value)}
                                                       size="small" variant="filled" fullWidth/>
                                        </Box>

                                        <Box mt="1em"/>

                                        <Box sx={{display: 'flex', gap: '20px'}}>
                                            <TextField label="Nom de la licence" value={licenceLibelle || ''}
                                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLicenceLibelle(event.target.value)}
                                                       size="small" variant="filled" fullWidth/>

                                            <TextField label="Lien vers les détails de la licence"
                                                       value={licenceURL || ''}
                                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLicenceURL(event.target.value)}
                                                       size="small" variant="filled" fullWidth/>
                                        </Box>

                                        <Box mt="2em"/>
                                    </Box>

                                    <Box sx={{display: 'flex', gap: '10px', justifyContent: 'end'}}>
                                        <Button onClick={handleClose}>Annuler</Button>
                                        <Button variant="contained" onClick={handleSubmit}>Enregistrer</Button>
                                    </Box>
                                </Box>
                            }

                            {extended &&
                                <Box sx={{
                                    display: 'flex',
                                    flex: '1 1 70%',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: 1,
                                    borderLeft: extended ? '1px solid #EFEFEF' : 'none'
                                }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                            marginTop: '8px',
                                            width: '100%',
                                            alignItems: 'center',
                                            padding: '0 0 0 20px'
                                        }}>

                                        {/*<Box sx={{display: 'flex'}}>*/}
                                        {/*    <Paper*/}
                                        {/*        component="form"*/}
                                        {/*        sx={{*/}
                                        {/*            p: '2px 4px',*/}
                                        {/*            display: 'flex',*/}
                                        {/*            alignItems: 'center',*/}
                                        {/*            width: 400,*/}
                                        {/*            height: '4.5vh'*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        <IconButton component="label" sx={{p: '10px'}} aria-label="search">*/}
                                        {/*            <HiSearch/>*/}
                                        {/*        </IconButton>*/}

                                        {/*        <InputBase*/}
                                        {/*            sx={{ml: 1, flex: 1}}*/}
                                        {/*            placeholder="Rechercher une image"*/}
                                        {/*            inputProps={{'aria-label': 'Rechercher une image'}}*/}
                                        {/*            onChange={(event: any) => setFilterQ(event.target.value)}*/}
                                        {/*        />*/}
                                        {/*    </Paper>*/}

                                        {/*    <FormControl sx={{width: '75%', margin: 0}}>*/}

                                        {/*        <Select*/}
                                        {/*            labelId="cat"*/}
                                        {/*            id="select-categories"*/}
                                        {/*            label="Catégories"*/}
                                        {/*            defaultValue={defaultCategory}*/}
                                        {/*            onChange={(event: any) => {*/}
                                        {/*                setFilterCat(event.target.value)*/}
                                        {/*                setLoading(true)*/}
                                        {/*            }}*/}
                                        {/*        >*/}
                                        {/*            {<MenuItem key="0" value=""> Toutes catégories </MenuItem>}*/}

                                        {/*            {mediaCategories.map((categorie: any) => (*/}
                                        {/*                <MenuItem key={categorie.id}*/}
                                        {/*                          value={categorie.id}> {categorie.name} </MenuItem>*/}
                                        {/*            ))}*/}
                                        {/*        </Select>*/}
                                        {/*    </FormControl>*/}
                                        {/*</Box>*/}

                                        {/*<Box sx={{width: '100%', display: 'flex', justifyContent: 'end', gap: '10px'}}>*/}
                                        {/*    <IconButton component="label" className="filter-icon" aria-label="search"*/}
                                        {/*                onClick={() => setImageList(false)}>*/}
                                        {/*        <HiMenu/>*/}
                                        {/*    </IconButton>*/}

                                        {/*    <IconButton component="label" className="filter-icon" aria-label="search"*/}
                                        {/*                onClick={() => setImageList(true)}>*/}
                                        {/*        <HiViewGrid/>*/}
                                        {/*    </IconButton>*/}
                                        {/*</Box>*/}

                                        <Box sx={{width: '100%', display: 'flex'}}>

                                            <List
                                                sort={{field: 'fileName', order: 'ASC'}}
                                                resource={'media'}
                                                filters={[
                                                    <SearchInput name="q" source="q" alwaysOn/>,
                                                    <SelectInput
                                                        label="Catégorie"
                                                        source="category"
                                                        defaultValue={defaultCategory}
                                                        choices={mediaCategories.map((category: Category) => ({
                                                            id: category.id,
                                                            name: category.name
                                                        }))}
                                                    />
                                                    // <Select
                                                    //     labelId="cat"
                                                    //     id="select-categories"
                                                    //     label="Catégories"
                                                    //     defaultValue={defaultCategory}
                                                    //     onChange={(event: any) => {
                                                    //         setFilterCat(event.target.value)
                                                    //         setLoading(true)
                                                    //     }}
                                                    // >
                                                    //     {<MenuItem key="0" value=""> Toutes catégories </MenuItem>}
                                                    //
                                                    //     {mediaCategories.map((categorie: any) => (
                                                    //         <MenuItem key={categorie.id}
                                                    //                   value={categorie.id}> {categorie.name} </MenuItem>
                                                    //     ))}
                                                    // </Select>
                                                    // <ReferenceField
                                                    //     source="category"
                                                    //     reference="media/categories"
                                                    //     label="Catégorie"
                                                    // >
                                                    //     <AutocompleteInput
                                                    //         label="Activité principale"
                                                    //         name="activity"
                                                    //         blurOnSelect={false}
                                                    //         optionText="name"
                                                    //         optionValue="id"
                                                    //         debounce={100}
                                                    //     />
                                                    // </ReferenceField>
                                                ]}
                                                hasCreate={false}
                                                exporter={false}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            >
                                                <Pagination rowsPerPageOptions={[5, 10, 25]}/>

                                                <Datagrid
                                                    optimized
                                                    rowClick={(id, resource, record) => {
                                                        handleSelect(record)
                                                        return false
                                                    }}
                                                    bulkActionButtons={false}
                                                >
                                                    <FunctionField
                                                        source="fileName"
                                                        label="Aperçu"
                                                        render={(record: Media) =>
                                                            <img
                                                                src={`${String(process.env.REACT_APP_IMAGE_URL)}/${record.fileName}`}
                                                                alt={record.description}
                                                                width="150" height="150"
                                                                style={{
                                                                    objectFit: 'contain'
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    <RaTextField source="fileName" label="Nom du fichier"/>
                                                    <ReferenceField
                                                        source="category"
                                                        reference="media/categories"
                                                        label="Catégorie"
                                                    >
                                                        <RaTextField source="name"/>
                                                    </ReferenceField>
                                                </Datagrid>
                                            </List>
                                            {/* <Box sx={{width: "20%"}}>
                                                    <h6>Tags</h6>

                                                </Box> */}
                                            {/*<Box sx={{width: 1, height: '50vh'}}>*/}
                                            {/*    <DataGrid*/}
                                            {/*        getRowId={(row) => row.id}*/}
                                            {/*        rows={mediaImages}*/}
                                            {/*        rowCount={450}*/}
                                            {/*        rowHeight={90}*/}
                                            {/*        columns={columns}*/}
                                            {/*        pageSize={25}*/}
                                            {/*        rowsPerPageOptions={[10, 25]}*/}
                                            {/*        onRowClick={(item: any) => handleSelect(item.row)}*/}
                                            {/*    />*/}
                                            {/*</Box>*/}
                                            {/*{imageList ? (*/}

                                            {/*    <ImageList cols={cols}*/}
                                            {/*               sx={{height: mediaImagesFiltered.length > 4 ? '50vh' : 'none'}}*/}
                                            {/*               variant="woven">*/}
                                            {/*        {mediaImagesFiltered && mediaImagesFiltered.map((item: any) => (*/}
                                            {/*            <Box*/}
                                            {/*                key={item.id}*/}
                                            {/*                className={selectedItem && selectedItem?.id == item.id ? 'list-item-active' : 'list-item'}*/}
                                            {/*                onClick={() => handleSelect(item)}*/}
                                            {/*            >*/}
                                            {/*                <ImageListItem sx={{*/}
                                            {/*                    height: {lg: 219, md: 150},*/}
                                            {/*                    width: '-webkit-fill-available;'*/}
                                            {/*                }}*/}
                                            {/*                >*/}
                                            {/*                    <img*/}
                                            {/*                        src={`${String(process.env.REACT_APP_IMAGE_URL)}/${item.fileName}`}*/}
                                            {/*                        srcSet={`${String(process.env.REACT_APP_IMAGE_URL)}/${item.fileName}`}*/}
                                            {/*                        alt={item.description}*/}
                                            {/*                        loading="lazy"*/}
                                            {/*                        className="list-item-img"*/}
                                            {/*                    />*/}
                                            {/*                    <ImageListItemBar*/}
                                            {/*                        className="list-item-bar"*/}
                                            {/*                        title={item.description}*/}
                                            {/*                        // subtitle={item.name}*/}
                                            {/*                        actionIcon={*/}
                                            {/*                            <IconButton*/}
                                            {/*                                sx={{color: 'rgba(255, 255, 255, 0.54)'}}*/}
                                            {/*                                aria-label={`Information about ${item.description}`}*/}
                                            {/*                            >*/}
                                            {/*                            </IconButton>*/}
                                            {/*                        }*/}
                                            {/*                    />*/}
                                            {/*                </ImageListItem>*/}
                                            {/*            </Box>*/}
                                            {/*        ))}*/}
                                            {/*    </ImageList>*/}

                                            {/*) : (*/}
                                            {/*    <Box sx={{width: 1, height: '50vh'}}>*/}
                                            {/*        <DataGrid*/}
                                            {/*            getRowId={(row) => row.id}*/}
                                            {/*            rows={mediaImagesFiltered}*/}
                                            {/*            rowHeight={90}*/}
                                            {/*            columns={columns}*/}
                                            {/*            pageSize={4}*/}
                                            {/*            rowsPerPageOptions={[4]}*/}
                                            {/*            onRowClick={(item: any) => handleSelect(item.row)}*/}
                                            {/*        />*/}
                                            {/*    </Box>*/}
                                            {/*)*/}
                                            {/*}*/}
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            gap: '10px',
                                            width: 1/*marginRight: '20px'*/
                                        }}>
                                            <Button onClick={handleShrunk}>Annuler</Button>
                                            <Button variant="contained" onClick={handleChoose}
                                                    disabled={!selected}>Sélectionner</Button>
                                        </Box>

                                    </Box>
                                </Box>
                            }
                        </Box>

                    </Box>
                </Modal.Body>

            </Modal>
        </>
    )
}

const useColsForWidth = () => {
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    const md = useMediaQuery(theme.breakpoints.up('md'))
    const lg = useMediaQuery(theme.breakpoints.up('lg'))
    const xl = useMediaQuery(theme.breakpoints.up('xl'))
    // there are all dividers of 24, to have full rows on each page
    if (xl) return 4
    if (lg) return 3
    if (md) return 2
    if (sm) return 2
    return 2
}

const columns: GridColDef[] = [
    {
        field: 'fileName',
        headerName: 'Aperçu',
        width: 180,
        renderCell: (params) => {
            return (
                <img src={`${String(process.env.REACT_APP_IMAGE_URL)}/${params.value}`} width="150" height="150"/>
            )
        }
    },
    {
        field: 'description',
        headerName: 'Nom de l\'image',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => {
            return `${params.row.description}`
        }
    },
    {
        field: 'destination',
        headerName: 'Nom du fichier',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => {
            return `${params.row.fileName}`
        }
    },
    {field: 'category.name', headerName: 'Catégorie', width: 150}
]

export default ModalPhotos
