import DatagridProducers from '@/producers/components/listFields'
import ListFilters from '@/producers/components/listFilters'
import {Alert, AlertTitle} from '@mui/material'
import * as React from 'react'
import {Fragment, useEffect} from 'react'
import {List, Pagination, SearchInput} from 'react-admin'

export default function ProducerList() {
    return (
        <>
            <Alert severity="info" style={{width: '100%', marginTop: 20}}>
                <AlertTitle>Structure des données</AlertTitle>
                Les entreprises regroupent les différents établissement qui lui appartiennent (magasins, points de
                vente...), elles ne sont pas affichées sur la carte contrairement aux établissements. Ajoutez une
                entreprise pour ajouter ensuite un établissement !
            </Alert>
            <List
                sort={{field: 'name', order: 'ASC'}}
                perPage={25}
                filters={[<SearchInput name="q" source="q" alwaysOn/>]}
                exporter={false}
                aside={<ListFilters/>}

            >
                <TableList/>
            </List>
        </>
    )
}

const TableList = () => {

    useEffect((): any => {
        document.title = 'Producteurs'
    }, [])

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25]}/>
            <DatagridProducers/>
        </Fragment>
    )
}
