import ModalPhotos from '@/layout/ModalPhotos'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    GlobalStyles,
    ToggleButton,
    Typography,
    TextField,
    Grid, FormGroup, FormControlLabel, Switch
} from '@mui/material'
import {
    AlignmentButtons,
    ClearButtons, DefaultEditorOptions,
    FormatButtons,
    LevelSelect,
    LinkButtons,
    ListButtons,
    QuoteButtons,
    RichTextInput,
    RichTextInputToolbar,
    useTiptapEditor
} from 'ra-input-rich-text'
import React, {ReactNode, useCallback, useState} from 'react'
import Link from '@tiptap/extension-link'
import ImageIcon from '@mui/icons-material/Image'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import LinkOffIcon from '@mui/icons-material/LinkOff'

export const EditorOptions = {
    ...DefaultEditorOptions,
    extensions: [
        ...DefaultEditorOptions.extensions,
        Link.configure({
            HTMLAttributes: {
                class: 'link',
            },
            openOnClick: false,
            autolink: false,
            validate: href => /^https:\/\//.test(href)
        }),
    ],
}

export default function DefaultEditor({
                                          source = 'description',
                                          label = 'Déscription',
    children
                                      }: {
    source?: string,
    label?: string,
    children?: ReactNode
}) {


    return (
        <Box sx={{
            width: '100%'
        }}>
            <GlobalStyles
                styles={{
                    '.ProseMirror': {
                        minHeight: 200
                    }
                }}
            />
            <Typography variant="h6" gutterBottom>{label}</Typography>
            {children}
            <RichTextInput
                name={source}
                source={source}
                label=" "
                fullWidth
                toolbar={
                    <RichTextInputToolbar size="medium">
                        <LevelSelect size="medium"/>
                        <FormatButtons size="medium"/>
                        <AlignmentButtons size="medium"/>
                        <ListButtons size="medium"/>
                        <LinkButtons size="medium"/>
                        <QuoteButtons size="medium"/>
                        <ClearButtons size="medium"/>
                        {/*<ModalPhotos size="medium" render="toogle"/>*/}
                        <LinkButton/>
                    </RichTextInputToolbar>
                }
            />
        </Box>
    )
}

function LinkButton() {

    const editor = useTiptapEditor()
    const [hasText, setHasText] = useState(false)
    const [formData, setFormData] = useState({link: '', text: '', blank: false})
    const [isOpen, setIsOpen] = useState(false)

    const handleOpen = useCallback(() => {

        const previousUrl = editor.getAttributes('link').href
        const previousTarget = editor.getAttributes('link').target

        // select all mark text
        editor.chain().focus().extendMarkRange('link').run()
        setHasText(!editor.view.state.selection.empty)

        const {state} = editor
        const {selection} = state
        const {from, to} = selection
        const text = state.doc.textBetween(from, to, ' ')

        setFormData(data => {
            return {
                ...data,
                ...{link: previousUrl ? previousUrl : ''},
                ...{blank: previousTarget === '_blank'},
                ...{text: text !== '' ? text : ''}
            }
        })

        setIsOpen(true)

    }, [editor])

    const setLink = useCallback(() => {

        // empty
        if (formData.link === '') {
            // console.log('link empty')
            editor.chain().focus().extendMarkRange('link').unsetLink().run()

            return
        }

        // update link
        editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({
                href: formData.link,
                target: formData.blank ? '_blank' : null
                // title: 'test'
            })
            .command(({tr}) => {
                const currentSelectionIsEmpty = editor.view.state.selection.empty
                if (currentSelectionIsEmpty) {
                    tr.insertText(formData.text)
                } else {
                    // console.log('edit from selection', tr, tr.curSelection.replace(tr, new Slice('22',0, 1)))
                }
                return true
            })
            .run()

        setIsOpen(false)

    }, [formData, editor])

    return editor ? (
        <>
            <ToggleButton
                aria-label="Insérer un lien"
                title="Insérer un lien"
                value="link"
                onClick={handleOpen}
                sx={{mR: 8, mB: 8, p: '11px'}}
            >
                <InsertLinkIcon fontSize="inherit"/>
            </ToggleButton>
            <ToggleButton
                aria-label="Enlever le lien"
                title="Enlever le lien"
                value="link"
                onClick={() => editor.chain().focus().unsetLink().run()}
                disabled={!editor.isActive('link')}
                sx={{mR: 8, mB: 8, p: '11px'}}
            >
                <LinkOffIcon fontSize="inherit"/>
            </ToggleButton>
            <Dialog
                open={isOpen}
                // onClose={onCancel}
            >
                <DialogContent>
                    <Typography variant={'h6'} gutterBottom>Insérer/modifier un lien</Typography>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                color="primary"
                                name={'link'}
                                placeholder="https://..."
                                autoFocus={true}
                                value={formData.link}
                                variant="outlined"
                                size={'medium'}
                                fullWidth
                                onChange={(event) => setFormData(data => {
                                    return {...data, ...{link: event.target.value}}
                                })}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                color="primary"
                                name={'text'}
                                placeholder="Mon lien"
                                InputProps={{
                                    readOnly: hasText,
                                }}
                                value={formData.text}
                                variant="outlined"
                                fullWidth
                                size={'medium'}
                                onChange={(event) => setFormData(data => {
                                    return {...data, ...{text: event.target.value}}
                                })}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData.blank}
                                            onChange={(event) => setFormData(data => {
                                                return {...data, ...{blank: event.target.checked}}
                                            })}
                                        />
                                    }
                                    label="Ouvrir dans un nouvel onglet"
                                />
                            </FormGroup>

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'space-between'
                    }}
                >
                    <Button onClick={() => setIsOpen(false)}>Fermer</Button>
                    <Button onClick={setLink} type="submit" variant={"contained"}>Insérer le lien</Button>
                </DialogActions>
            </Dialog>
        </>
    ) : null
}
