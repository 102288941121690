import {Box, Button, IconButton, Input} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useNotify, useRecordContext, useRedirect, useRefresh} from 'react-admin'
import {RiCloseCircleFill} from 'react-icons/ri'

const MediaUpload = (props: any) => {

    const {source} = props

    const [url, setURL] = useState('')
    const record = useRecordContext()
    const [imagePreview, setImagePreview] = useState<{
        result: string | ArrayBuffer | null,
        width: number,
        height: number
    } | null>(null)
    const [file, setFile] = useState<File | null>(null)

    const notify = useNotify()
    const redirect = useRedirect()
    const refresh = useRefresh()

    const handleRemoveUploadFile = () => {
        setURL('')
    }

    const onChange = (e: any) => {
        // Set the file
        if (e.target.files[0]) {
            setFile(e.target.files[0])
        } else {
            return
        }
    }

    useEffect(() => {
        if (file) {
            const fileReader = new FileReader()

            fileReader.onload = () => {
                const img = new Image()
                img.onload = () => {
                    setImagePreview({
                        height: img.height,
                        width: img.width,
                        result: fileReader.result as string
                    })
                }
                img.src = fileReader.result as string
            }

            fileReader.readAsDataURL(file)
        }
    }, [file])

    useEffect(() => {
        if (record && record?.fileName !== '') {
            setURL(`${String(process.env.REACT_APP_IMAGE_URL)}/${record.fileName}`)
        }
    }, [record, source])

    useEffect(() => {
        if (file) {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('description', file?.name)
            formData.append('category', '1')
            formData.append(
                'metas',
                JSON.stringify({
                    size: imagePreview
                        ? {
                            width: imagePreview.width,
                            height: imagePreview.height
                        }
                        : null,
                    credit: {
                        name: '',
                        url: ''
                    },
                    licence: {
                        name: '',
                        url: ''
                    }
                })
            )

            if (record) {
                formData.append('isDeleted', record?.fileName)
                formData.append('id', String(record?.id))
            }

            // Media's uploading
            const endpointUpload = String(process.env.REACT_APP_DB_LOPT) + '/media/upload'

            fetch(endpointUpload, {method: 'POST', body: formData})
                .then((response: any) => response.json())
                .then((data) => {
                    if (data.result === 1) {
                        notify(data.status, {
                            type: 'info',
                            messageArgs: {smart_count: 1}
                        })
                        record ? refresh() : redirect('edit', 'media', data.source.id)
                    } else {
                        notify(data.status, {
                            type: 'warning',
                            messageArgs: {smart_count: 1}
                        })
                    }
                })
        }
    }, [imagePreview])


    return (
        <Box
            sx={{
                marginRight: 'auto',
                marginLeft: 'auto',
                height: '20vh',
                width: '28vh',
                border: !url ? '2px dashed #59626B' : 'none',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                placeContent: 'center',
                alignItems: 'center',
                gap: '10px'
            }}>

            {!url ? (
                <>
                    {/* <h6>Glisser/Déposer une image</h6> */}

                    <Button
                        variant="contained"
                        component="label"
                        sx={{width: '50%'}}
                    >
                        Parcourir
                        <Input
                            onChange={onChange}
                            hidden /*accept="image/*"*/
                            type="file"
                        />
                    </Button>
                </>
            ) : (
                <Box sx={{position: 'relative'}}>
                    <Box sx={{width: 1, height: 1, p: 2}} component="img" src={url}/>
                    <IconButton
                        sx={{position: 'absolute', color: '#d32c28', top: '10px', right: '10px', fontSize: '1.5em'}}
                        onClick={handleRemoveUploadFile}>
                        <RiCloseCircleFill/>
                    </IconButton>
                </Box>
            )}
        </Box>
    )
}

export default MediaUpload
