import {Box, Typography} from '@mui/material'
import {ArrayInput, required, SelectInput, SimpleFormIterator, TextInput} from 'react-admin'
import React from 'react'

export default function FieldsPublication() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Présence sur le site</Typography>
            <Box sx={{display: 'flex', gap: '20px'}}>
                <TextInput
                    disabled
                    source="id"
                    name="id"
                    id="identifiant"
                    required
                    fullWidth
                />
                <SelectInput
                    className="selectInput"
                    source="is_published"
                    format={v => String(v)}
                    optionValue="id"
                    optionText="LIBELLE"
                    label="Activer l'entreprise"
                    defaultValue={false}
                    choices={[
                        {id: false, LIBELLE: 'Non'},
                        {id: true, LIBELLE: 'Oui'}
                    ]}
                    emptyValue="empty"
                    fullWidth
                    required
                    validate={[required()]}
                />
                <SelectInput
                    className="selectInput"
                    source="is_validated"
                    format={v => String(v)}
                    optionValue="id"
                    optionText="LIBELLE"
                    label="Entreprise active"
                    defaultValue={false}
                    choices={[
                        {id: false, LIBELLE: 'Non'},
                        {id: true, LIBELLE: 'Oui'}
                    ]}
                    emptyValue="empty"
                    fullWidth
                    required
                    validate={[required()]}
                />
            </Box>
        </Box>
    )
}
