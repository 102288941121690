import {BooleanNumField} from '@/components/field'
import {baseFilters} from '@/components/filter/base'
import Aside from '@/products/Aside'
import {Product} from '@/types'
import {Theme, useMediaQuery} from '@mui/material'
import * as React from 'react'
import {Fragment, useCallback, useEffect} from 'react'
import {Datagrid, DateField, FunctionField, List, Pagination, SimpleList, TextField, useCreatePath} from 'react-admin'
import {Link} from 'react-router-dom'

export default function ProductList() {
    return (
        <List
            sort={{field: 'id', order: 'ASC'}}
            perPage={25}
            exporter={false}
            aside={<Aside/>}
            filters={baseFilters}
        >
            <TableList/>
        </List>
    )
}

const TableList = () => {

    const createPath = useCreatePath()

    useEffect((): any => {
        document.title = 'Produits'
    }, [])

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => e.stopPropagation(), [])

    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>
            {isSmall ?
                <SimpleList
                    primaryText={record => record.name}
                    // secondaryText={record => `${record.categoryName}`}
                    secondaryText={record => `${record.categoryName}, ${record.typeName}`}
                    // tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
                : <Datagrid
                    size="medium"
                    sx={{'width': '-webkit-fill-available'}}
                    optimized
                    rowClick="edit"
                >
                    <TextField source="id"/>
                    <TextField source="article"/>
                    <FunctionField
                        source="name"
                        label="Libellé"
                        render={(record: Product) =>
                            <Link
                                to={createPath({resource: 'products', type: 'edit', id: record.id})}
                                onClick={handleClick}
                                style={{textDecoration: 'none'}}
                            >
                                <TextField source="name" color="primary"/>
                                <TextField source="name_alt" color="black" sx={{
                                    display: 'block',
                                    fontStyle: 'italic',
                                    color: 'black !important',
                                    fontSize: '12px'
                                }}/>
                            </Link>
                        }
                    />
                    {/*<ReferenceField source="id" reference="products" link="edit" label="Libellé">*/}
                    {/*    <TextField source="name" />*/}
                    {/*    <TextField source="name_alt" color="black" sx={{ display: 'block', fontStyle: 'italic', color: 'black !important', fontSize: '12px'}} />*/}
                    {/*</ReferenceField>*/}
                    <TextField source="typeName" label="Type"/>
                    <TextField source="categoryName" label="Catégorie"/>
                    <DateField source="updated_at"/>
                    <TextField source="highestTrustValue" label="Indice de confiance max"/>
                    <FunctionField
                        source="is_published"
                        label="Publié"
                        render={(record: any, source: any) =>
                            <BooleanNumField
                                label="Publié"
                                source={source}
                            />
                        }
                    />
                </Datagrid>
            }
        </Fragment>
    )
}
