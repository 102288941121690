import {LinearProgressWithLabel} from '@/components/field/LinearProgressField'
import {Box, Card, CardContent, Tab, Tabs, Typography} from '@mui/material'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import {ShowBase, useEditController} from 'react-admin'
import './style.css'

const ContributionShow = () => {
    const {record, isLoading: loading} = useEditController()
    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => {
        if (!loading) {
            document.title = record?.id || 'Détails'
        }
    }, [record, loading])

    const returnValue = (val: boolean | number) => {
        if (val == 2 || val == true) {
            return 'Oui'
        } else if (val == 1 || val == false) {
            return 'Non'
        } else if (val == 0) {
            return 'NSP'
        } else {
            return ''
        }
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', flex: 0.1},
        {field: 'name', headerName: 'Nom du produit', flex: 1},
        {field: 'category', headerName: 'Catégorie', flex: 0.5},
        {field: 'heritage', headerName: 'Patrimoine gastro. du terroir', flex: 0.5},
        {field: 'is_1932', headerName: '1932', flex: 0.5},
        {field: 'is_1992', headerName: '1992', flex: 0.5},
        {field: 'is_2022', headerName: '2022', flex: 0.5},
        {field: 'is_custom', headerName: 'Produit ajouté ?', flex: 0.5},
        {field: 'is_produced', headerName: 'Produit par l\'user', flex: 0.7}
    ]

    const rows = record?.products.map((product: any) => ({
        id: product.id,
        name: product.product?.name ? product.product?.name : product.name,
        category: product.product?.categoryName ?? null,
        heritage: returnValue(product.heritage),
        is_1932: returnValue(product.is_1932),
        is_1992: returnValue(product.is_1992),
        is_2022: returnValue(product.is_2022),
        is_custom: returnValue(product.is_custom),
        is_produced: returnValue(product.is_produced),
        status: product.status
    }))

    const CardDetails = () => {
        const {roles} = record?.user

        const hasUserRole = roles.includes('ROLE_USER')
        const hasProducerRole = roles.includes('ROLE_PRODUCER')
        return (
            <Card>
                <CardContent sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Informations sur le contributeur
                        </Typography>
                        <Box ml={2}>
                            <Typography variant="body1" gutterBottom>
                                <strong>ID:</strong> {record?.user.id}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Civilité:</strong> {record?.user.civility}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Email:</strong> {record?.user.email}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Prénom:</strong> {record?.user.firstName}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Nom:</strong> {record?.user.lastName}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Rôles:</strong>{' '}
                                {hasUserRole && <span>Utilisateur</span>}
                                {hasUserRole && hasProducerRole && <span>, </span>}
                                {hasProducerRole && <span>Producteur</span>}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Créé le:</strong> {record?.user.created_at}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Mis à jour le:</strong> {record?.user.updated_at}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{textAlign: 'right'}}>
                        <Typography variant="h6" gutterBottom>
                            Informations sur le terroir
                        </Typography>
                        <Box ml={2}>
                            <Typography variant="body1" gutterBottom>
                                <strong>ID :</strong> {record?.terroir.id}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>RZ :</strong> {record?.terroir.code}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Nom du terroir :</strong> {record?.terroir.article + ' ' + record?.terroir.name}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        )
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }

    const filteredRows = rows?.filter((row: any) => {
        if (activeTab == 0) {
            return row.status == 0
        } else {
            return row.status == 1
        }
    })

    const getRowClassName = (params: any) => {
        const row = params.row

        if (row.heritage == 'Non' || row.heritage == 'NSP') {
            return 'valid'
        } else {
            if (row.is_1932 && row.is_1992 && row.is_2022)
                return 'valid'
            return ''
        }
    }

    const getTabLabel = (index: number) => {
        if (index === 0) {
            const count = rows?.filter((row: any) => row.status === 0)?.length
            return `En cours (${count})`
        } else {
            const count = rows?.filter((row: any) => row.status !== 0)?.length
            return `Validés (${count})`
        }
    }

    return (
        <ShowBase>
            <Box p="1em">
                <CardDetails/>
                <Box mt="3em"/>
                <Box>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography variant="h6" gutterBottom>
                            Détails de la contribution : {record.id}
                        </Typography>
                        <Box sx={{width: '20%'}}>
                            <LinearProgressWithLabel value={record?.progress}/>
                        </Box>
                    </Box>
                    <Box sx={{mb: 2}}>
                        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary">
                            <Tab label={getTabLabel(0)}/>
                            <Tab label={getTabLabel(1)}/>
                        </Tabs>
                    </Box>
                    <div style={{height: 800, width: '100%'}}>
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            pageSize={10}
                            getRowClassName={getRowClassName}
                        />
                    </div>
                </Box>
            </Box>
        </ShowBase>
    )
}

export default ContributionShow
