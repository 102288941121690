import {FullNameField} from '@/components/field'
import MediaUpload from '@/components/media/MediaUpload'
import {Box, Grid, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {
    AutocompleteInput,
    Edit,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    useEditController,
    useTranslate
} from 'react-admin'

const transform = (data: any) => {

    console.log({
        ...data,
        ...(data && data.metas && {metas: JSON.stringify(data.metas)})
    })

    return {
        ...data,
        ...(data && data.metas && {metas: JSON.stringify(data.metas)})
    }
}

const MediaEdit = () => {
    const translate = useTranslate()
    const {record, isLoading} = useEditController()

    // ###################
    // TITRE DE LA PAGE
    useEffect((): any => {
        if (isLoading)
            return null
        else document.title = record.description
    }, [record])

    return (
        <Edit
            title={<ImageTitle/>}
            mutationMode="optimistic"
            transform={transform}
        >
            <SimpleForm warnWhenUnsavedChanges>
                <Grid>

                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.medias.fieldGroups.general'
                            )}
                        </Typography>

                        <Box display={{xs: 'block', md: 'flex', gap: '20px', alignItems: 'center'}}>
                            <Box sx={{width: '100%'}}>

                                <TextInput
                                    disabled
                                    label="resources.medias.fields.id"
                                    source="id"
                                    validate={req}
                                    fullWidth
                                />

                                <TextInput
                                    disabled
                                    label="resources.medias.fields.source"
                                    source="fileName"
                                    id="source"
                                    validate={req}
                                    fullWidth
                                />

                                <TextInput
                                    label="resources.medias.fields.description"
                                    source="description"
                                    id="description"
                                    validate={req}
                                    fullWidth
                                />

                                <ReferenceInput
                                    name="category"
                                    label="Catégorie"
                                    reference="media/categories"
                                    source="category"
                                >
                                    <AutocompleteInput
                                        name="category"
                                        optionText="name"
                                        optionValue="id"
                                        debounce={100}
                                    />
                                </ReferenceInput>
                            </Box>

                            <Box>
                                <MediaUpload/>
                            </Box>
                        </Box>

                        <Box mt="2em"/>

                        <TextInput
                            label="resources.medias.fields.height"
                            source="metas.size.height"
                            id="hauteur"
                            fullWidth
                        />

                        <TextInput
                            label="resources.medias.fields.width"
                            source="metas.size.width"
                            id="largeur"
                            fullWidth
                        />

                        <Box mt="2em"/>

                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.medias.fieldGroups.credits'
                            )}
                        </Typography>

                        <TextInput
                            label="resources.medias.fields.author"
                            source="metas.credit.name"
                            id="auteur"
                            fullWidth
                        />

                        <TextInput
                            label="resources.medias.fields.author_url"
                            source="metas.credit.url"
                            id="auteur_url"
                            fullWidth
                        />

                        <TextInput
                            label="resources.medias.fields.license"
                            source="metas.licence.name"
                            id="credit"
                            fullWidth
                        />

                        <TextInput
                            label="resources.medias.fields.license_url"
                            source="metas.licence.url"
                            id="credit_url"
                            fullWidth
                        />

                        <Box mt="2em"/>

                        <TextInput
                            label="resources.medias.fields.created_at"
                            source="created_at"
                            disabled={true}
                            fullWidth
                            value={(v: string) => v.toLowerCase()}
                        />

                        <TextInput
                            label="resources.medias.fields.updated_at"
                            source="updated_at"
                            disabled={true}
                            fullWidth
                        />
                    </Box>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}

const req = [required()]
const ImageTitle = () => <FullNameField size="32" sx={{margin: '5px 0'}}/>

export default MediaEdit
