import FullNameField from '@/components/field/FullNameField'
import DefaultEditor from '@/components/form/DefaultEditor'
import PreviewImage from '@/layout/PreviewImage'

import ProductLinked from '@/products/ProductLinked'
import {ProductTerroir, Terroir} from '@/types'

import LaunchIcon from '@mui/icons-material/Launch'
import {Box, Grid, Link, Typography} from '@mui/material'
import {useEffect} from 'react'
import {
    AutocompleteArrayInput,
    Edit,
    NumberInput,
    ReferenceArrayInput,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useEditController,
    useNotify,
    useTranslate
} from 'react-admin'

const TerroirEdit = () => {

    const translate = useTranslate()
    const {record} = useEditController()

    useEffect(() => {
        if (record)
            document.title = record.name
    }, [record])

    return (
        <Edit mutationMode="optimistic" title={<TerroirTitle/>}>
            <SimpleForm
                warnWhenUnsavedChanges
                noValidate
                toolbar={<FormToolbar/>}>
                <Grid container display={{xs: 'block', sm: 'flex'}} sx={{gap: '30px', justifyContent: 'space-between'}}>
                    <Grid item sm={4} md={4}>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.terroirs.fieldGroups.identity')}
                        </Typography>
                        <TextInput
                            disabled
                            source="id"
                            id="id"
                            validate={req}
                            fullWidth
                        />
                        <TextInput
                            disabled
                            name="code"
                            source="code"
                            id="code"
                            validate={req}
                            fullWidth
                        />
                        <TextInput
                            label="Nom"
                            source="name"
                            validate={req}
                            fullWidth
                        />
                        <TextInput
                            label="Nom complémentaire"
                            source="name_alt"
                            fullWidth
                        />
                        <TextInput
                            source="article"
                            fullWidth
                        />
                        <Box sx={{display: 'flex'}}>
                            <TextInput
                                type="text"
                                name="link_external"
                                source="link_external"
                                fullWidth
                            />
                            {record && record.link_external &&
                                <Link
                                    sx={{
                                        alignSelf: 'center',
                                        padding: '0 15px',
                                        height: '40px',
                                        pointerEvents: record.link_external ? 'inherit' : 'none',
                                        cursor: record.link_external ? 'pointer' : 'not-allowed'
                                    }}
                                    target="_blank"
                                    href={record.link_external}
                                >
                                    <LaunchIcon color={record.link_external ? 'primary' : 'disabled'}/>
                                </Link>
                            }
                        </Box>
                        <Box mt="3em"/>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.terroirs.fieldGroups.geo')}
                        </Typography>
                        <Box display={{xs: 'block', sm: 'flex'}}>
                            <Box flex={2} mr={{xs: 0, sm: '0.5em'}}>
                                <NumberInput
                                    label="Longitude"
                                    source="lng"
                                    fullWidth
                                    helperText={false}
                                    validate={req}
                                />
                            </Box>
                            <Box flex={2}>
                                <NumberInput
                                    label="Latitude"
                                    source="lat"
                                    fullWidth
                                    helperText={false}
                                    validate={req}
                                />
                            </Box>
                        </Box>
                        <Box mt="3em"/>
                        <TextInput
                            label="Crée le"
                            source="created_at"
                            disabled={true}
                            fullWidth
                        />
                        <TextInput
                            label="Modifié le"
                            source="updated_at"
                            disabled={true}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Grid>
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.products.fieldGroups.identitevisuelle')}
                            </Typography>
                            <PreviewImage
                                source="image"
                                resource="terroir"
                            />
                        </Grid>
                        <Box mt="4em"/>
                        <Grid>
                            {record && record.id &&
                                <ProductLinked<Terroir, ProductTerroir>
                                    productId={record.id}
                                    context="terroirs"
                                    enableTrust={true}
                                />
                            }
                        </Grid>
                        {/*<Box mt="4em" />*/}
                        {/*<Grid>*/}
                        {/*    <Typography variant="h6" gutterBottom>*/}
                        {/*        {translate('resources.terroirs.fieldGroups.communesliees')}*/}
                        {/*    </Typography>*/}
                        {/*    <ReferenceArrayInput*/}
                        {/*        name="communes"*/}
                        {/*        reference="communes"*/}
                        {/*        source="communes"*/}
                        {/*    >*/}
                        {/*        <AutocompleteArrayInput*/}
                        {/*            label="Communes Liees"*/}
                        {/*            name="communes"*/}
                        {/*            optionText="name"*/}
                        {/*            multiple={true}*/}
                        {/*            optionValue="id"*/}
                        {/*            debounce={250}*/}
                        {/*        />*/}
                        {/*    </ReferenceArrayInput>*/}
                        {/*</Grid>*/}
                        <Box mt="4em"/>
                        <Grid>
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.terroirs.fieldGroups.terroirsLimitrophes')}
                            </Typography>
                            <ReferenceArrayInput
                                name="neighbors"
                                reference="terroirs"
                                source="neighbors"
                            >
                                <AutocompleteArrayInput
                                    label="Terroirs"
                                    name="neighbors"
                                    optionText={option => `${option.code} -- ${option.name}`}
                                    multiple={true}
                                    optionValue="id"
                                    debounce={250}
                                />
                            </ReferenceArrayInput>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt="3em"/>
                <DefaultEditor/>
            </SimpleForm>
        </Edit>
    )
}

const req = [required()]

const TerroirTitle = () => <FullNameField size="32" sx={{margin: '5px 0'}}/>

const FormToolbar = () => {
    const notify = useNotify()

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton alwaysEnable className="save" label="Enregistrer et quitter"/>

                <SaveButton alwaysEnable className="save" label="Enregistrer" type="button" variant="text"
                            mutationOptions={{
                                onSuccess: data => {
                                    notify('ra.notification.updated', {
                                        type: 'info',
                                        messageArgs: {smart_count: 1}
                                    })

                                }
                            }}/>
            </Box>

            {/*<DeleteButton/>*/}
        </Toolbar>
    )
}

export default TerroirEdit
