import {Box, Button, CircularProgress, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useEditController, useInput} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {BsImage} from 'react-icons/bs'

import ModalPhotos from './ModalPhotos'

const PreviewImage = (props: any) => {

    const {resource, source} = props
    const {record} = useEditController()

    const [dataPicture, setDataPicture] = useState<any>([])
    const [loading, setLoading] = useState(true)

    const {setValue} = useFormContext()
    const {field} = useInput({source})

    useEffect((): any => {
        const getDataPicture = async () => {

            const id = record?.medias && record?.medias.featured ? record?.medias.featured.id : record?.avatar_id

            if (id) {
                const api = String(process.env.REACT_APP_DB_LOPT) + '/media/' + id
                const result = await fetch(api, {method: 'GET'})
                const json = await result.json()
                setDataPicture(json)
            }
            setLoading(false)
        }

        getDataPicture()

    }, [])

    useEffect((): any => {
        if (dataPicture) {
            setValue(source, dataPicture.id ? dataPicture.id : '')
        }
    }, [dataPicture])

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <Typography variant="h6" gutterBottom>Image à la une</Typography>
            <Box sx={{
                border: '1px solid #e0e0e3',
                borderRadius: '5px',
                backgroundColor: dataPicture.id ? 'none' : '#BDBDBD',
                height: '150px',
                display: 'flex',
                justifyContent: 'center',
                placeItems: 'center',
                fontSize: '5rem',
                color: 'white'
            }}>
                {loading && <CircularProgress disableShrink size="5rem"/>}
                {!loading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 1, height: 1}}>
                        {dataPicture.id ? (
                            <Box sx={{height: 1, objectFit: 'cover'}} component="img"
                                 src={String(process.env.REACT_APP_IMAGE_URL + '/' + dataPicture.fileName)}/>
                        ) : (
                            <BsImage/>
                        )}
                    </Box>
                }
            </Box>
            <Box sx={{display: 'flex', gap: '30px', justifyContent: 'space-between'}}>
                <ModalPhotos
                    render="button"
                    label="Sélectionner une image"
                    resource={resource}
                    target={dataPicture}
                    setTarget={setDataPicture}
                />
                <Button
                    variant="outlined"
                    disabled={!dataPicture || !dataPicture.id}
                    onClick={() => {
                        setDataPicture([])
                    }}
                    /*</Box>sx={{ pointerEvents: imageID != 0 ? "auto": "none" }} disabled={imageID ? true: false} */
                >
                    Supprimer l'image
                </Button>
            </Box>
            <input
                {...field}
                type="hidden"
            />
        </Box>
    )
}

export default PreviewImage
