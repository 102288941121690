import {Producer} from '@/types'
import {Alert, Typography} from '@mui/material'
import * as React from 'react'
import {BulkDeleteButton, DeleteButton, useListContext, useRecordContext} from 'react-admin'

export function BulkDeleteProducer() {

    const record = useRecordContext<Producer>()
    const {selectedIds} = useListContext()

    return (
        <BulkDeleteButton
            mutationMode="pessimistic"
            confirmTitle={`Suppression de ${selectedIds.length} entreprise${selectedIds.length > 1 ? 's' : ''}`}
            confirmContent={<>
                <Typography>Êtes-vous sûr de vouloir
                    supprimer {selectedIds.length > 1 ? 'ces' : 'cette'} entreprise{selectedIds.length > 1 ? 's' : ''} ?</Typography>
                <Alert severity="error" style={{width: '100%', marginTop: 20}}>
                    Les {record?.stores.length} établissement(s) associé(s) seront automatiquement supprimé(s)
                </Alert>
            </>}
        />
    )
}

export function DeleteButtonProducer() {

    const record = useRecordContext<Producer>()

    return (
        <DeleteButton
            mutationMode="pessimistic"
            confirmTitle="Suppression de l'entreprise"
            confirmContent={<>
                <Typography>Êtes-vous sûr de vouloir supprimer cette entreprise ?</Typography>
                <Alert severity="error" style={{width: '100%', marginTop: 20}}>
                    Les {record?.stores?.length} établissement(s) associé(s) seront automatiquement supprimé(s)
                </Alert>
            </>}
        />
    )
}
